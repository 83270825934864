import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const sliderSlice = createSlice({
  name: "slider",
  initialState: {
    images: [],
    loading: false,
  },

  reducers: {
    sliderRequested: (slider, action) => {
      slider.loading = true;
    },

    sliderReceived: (slider, action) => {
      slider.images = action.payload;
      slider.loading = false;
    },

    sliderRequestFailed: (slider, action) => {
      slider.loading = false;
    },
  },
});

export default sliderSlice;
const lang = localStorage.getItem("language")
  ? localStorage.getItem("language") == "en"
    ? 1
    : 2
  : 2;
const { sliderRequestFailed, sliderReceived, sliderRequested } =
  sliderSlice.actions;

const url = `Banner/BannersByCategory?categoryUniqueName=home.banner&langId=${lang}`;

export const loadBanner = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "GET",
      onStart: sliderRequested.type,
      onSuccess: sliderReceived.type,
      onError: sliderRequestFailed.type,
    })
  );
};
