import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const homeVesionSlice = createSlice({
  name: "vesion",
  initialState: {
    vesion: "",
    awardTime: [],
    loading: false,
  },

  reducers: {
    vesionRequested: (vesion, action) => {
      vesion.loading = true;
    },

    vesionReceived: (vesion, action) => {
      vesion.vesion = action.payload;
      vesion.loading = false;
    },

    vesionRequestFailed: (vesion, action) => {
      vesion.loading = false;
    },
    awardRequested: (vesion, action) => {
      vesion.loading = true;
    },

    awardReceived: (vesion, action) => {
      vesion.awardTime = action.payload;
      vesion.loading = false;
    },

    awardRequestFailed: (vesion, action) => {
      vesion.loading = false;
    },
  },
});

export default homeVesionSlice;
const lang = localStorage.getItem("language")
  ? localStorage.getItem("language") == "en"
    ? 1
    : 2
  : 2;
const { vesionRequestFailed, vesionReceived, vesionRequested } =
  homeVesionSlice.actions;

export const loadVesion = () => (dispatch) => {
  const url = `GenericContent/ContentByUniqueName?uniquename=vision.goals&langId=${lang}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "GET",
      onStart: vesionRequested.type,
      onSuccess: vesionReceived.type,
      onError: vesionRequestFailed.type,
    })
  );
};
const { awardRequestFailed, awardReceived, awardRequested } =
  homeVesionSlice.actions;

export const loadAwardTime = () => (dispatch) => {
  const url = `Events/Latest?count=1&langId=${lang}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "GET",
      onStart: awardRequested.type,
      onSuccess: awardReceived.type,
      onError: awardRequestFailed.type,
    })
  );
};
