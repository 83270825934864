import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const uploaderSlice = createSlice({
  name: "uploader",
  initialState: {
    loading: false,
    videoLoader:false,
    files: {
      photo: {},
      idPhoto: {},
      video: {},
    },
  },

  reducers: {
    uploadPhotoRequested: (uploadPhoto, action) => {
      uploadPhoto.loading = true;
    },

    uploadPhotoReceived: (uploadPhoto, action) => {
      uploadPhoto.files.photo = action.payload;
      uploadPhoto.loading = false;
    },

    uploadPhotoRequestFailed: (uploadPhoto, action) => {
      uploadPhoto.loading = false;
    },

    uploadIdPhotoRequested: (uploadIdPhoto, action) => {
      uploadIdPhoto.loading = true;
    },

    uploadIdPhotoReceived: (uploadIdPhoto, action) => {
      uploadIdPhoto.files.idPhoto = action.payload;
      uploadIdPhoto.loading = false;
    },

    uploadIdPhotoRequestFailed: (uploadIdPhoto, action) => {
      uploadIdPhoto.loading = false;
    },

    uploadVideoRequested: (uploadVideo, action) => {
      uploadVideo.videoLoader = true;
    },

    uploadVideoReceived: (uploadVideo, action) => {
      uploadVideo.files.video = action.payload;
      uploadVideo.videoLoader = false;
    },

    uploadVideoRequestFailed: (uploadVideo, action) => {
      uploadVideo.videoLoader = false;
    },
  },
});

export default uploaderSlice;
const {
  uploadPhotoRequested,
  uploadPhotoReceived,
  uploadPhotoRequestFailed,
  uploadIdPhotoRequested,
  uploadIdPhotoReceived,
  uploadIdPhotoRequestFailed,
  uploadVideoRequested,
  uploadVideoReceived,
  uploadVideoRequestFailed,
} = uploaderSlice.actions;

export const uploadPhoto = (data) => (dispatch) => {
  const url = `Award/UploadFile`;
  return dispatch(
    apiCallBegan({
      url,
      method: "post",
      data: data,
      onStart: uploadPhotoRequested.type,
      onSuccess: uploadPhotoReceived.type,
      onError: uploadPhotoRequestFailed.type,
    })
  );
};
export const uploadIdPhoto = (data) => (dispatch) => {
  const url = `Award/UploadFile`;
  return dispatch(
    apiCallBegan({
      url,
      method: "post",
      data: data,
      onStart: uploadIdPhotoRequested.type,
      onSuccess: uploadIdPhotoReceived.type,
      onError: uploadIdPhotoRequestFailed.type,
    })
  );
};
export const uploadVideo = (data) => (dispatch) => {
  const url = `Award/Uploadvideo?fileextention=mp4`;
  return dispatch(
    apiCallBegan({
      url,
      Headers: { "Content-Type": "multipart/form-data" },
      method: "post",
      data: data,
      onStart: uploadVideoRequested.type,
      onSuccess: uploadVideoReceived.type,
      onError: uploadVideoRequestFailed.type,
    })
  );
};
