import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import meuSlice from "./menu";
import sliderSlice from "./banner";
import sliderVesion from "./home-vesion";
import genericConetent from "./genericContentStore";
import award from "./award";
import api from "./middleware";
import awardSlice from "./award";
import gallerySlice from "./galleryStore";
import registrationSlice from "./registrationStore";
import uploaderSlice from "./uploader";
import getVisitorNumberSlice from "./general";
export default function store() {
  return configureStore({
    reducer: {
      menu: meuSlice.reducer,
      slider: sliderSlice.reducer,
      vesion: sliderVesion.reducer,
      genericConetent: genericConetent.reducer,
      award: awardSlice.reducer,
      gallery: gallerySlice.reducer,
      registration:registrationSlice.reducer,
      uploader: uploaderSlice.reducer,
      getVisitorNumber: getVisitorNumberSlice.reducer
    },
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), api ],
  });
}



