import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
const itemPerPage = 10
const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    images: [],
    totalPages: 0,
    loading: false,
  },

  reducers: {
    galleryRequested: (gallery, action) => {
      gallery.loading = true;
    },

    galleryReceived: (gallery, action) => {
      let list = [];
      action.payload.result.forEach((element, i) => {
        list.push({
          id: i,
          title: element.title,
          image: element.mainImageFullUrl,
        });
      });
      gallery.totalPages = Math.ceil(action.payload.totalCount / itemPerPage);
      gallery.images = list;
      gallery.loading = false;
    },

    galleryRequestFailed: (gallery, action) => {
      gallery.loading = false;
    },
  },
});

export default gallerySlice;
const lang = localStorage.getItem("language")
  ? localStorage.getItem("language") == "en"
    ? 1
    : 2
  : 2;
const { galleryRequestFailed, galleryReceived, galleryRequested } =
  gallerySlice.actions;

export const loadPhotos = (uniqueName, mediatype, pageNumber) => (dispatch) => {
  
  const url = `MediaGallery/Search?categoryUniqueName=${uniqueName}&mediatype=${mediatype}&pageSize=${itemPerPage}&pageNumber=${pageNumber}&langId=${lang}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "GET",
      onStart: galleryRequested.type,
      onSuccess: galleryReceived.type,
      onError: galleryRequestFailed.type,
    })
  );
};
