import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const awardSlice = createSlice({
  name: "awardbann",
  initialState: {
    award: {},
    loading: false,
  },

  reducers: {
    awardRequested: (award, action) => {
      award.loading = true;
    },

    awardReceived: (award, action) => {
      award.award = action.payload;
      award.loading = false;
    },

    awardRequestFailed: (award, action) => {
      award.loading = false;
    },
  },
});

export default awardSlice;
const lang = localStorage.getItem("language")
  ? localStorage.getItem("language") == "en"
    ? 1
    : 2
  : 2;
const { awardRequestFailed, awardReceived, awardRequested } =
  awardSlice.actions;

const url = `Banner/BannerByUniqueName?uniquename=award.2021&langId=${lang}`;

export const loadAward = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "GET",
      onStart: awardRequested.type,
      onSuccess: awardReceived.type,
      onError: awardRequestFailed.type,
    })
  );
};
