import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import Swal from "sweetalert2";
import i18n from "../localization/i18n";
const registrationSlice = createSlice({
  name: "registration",
  initialState: {
    loading: false,
    awardsCats: [],
    national: [],
    registration: {},
  },

  reducers: {
    registrationRequested: (registration, action) => {
      registration.loading = true;
    },

    registrationReceived: (registration, action) => {
      registration.registration = action.payload;
      registration.loading = false;
      Swal.fire({
        html: `<p className="w-full p-4 text-lg">${i18n.t(
          "thanks"
        )}</p><span className="text-sm text-red-600">${i18n.t("reference")} ${action.payload.reference}</span>`,
        confirmButtonText:i18n.t('ok')
      });
      
    },

    registrationRequestFailed: (registration, action) => {
      registration.loading = false;
    },

    awardsCategoriesRequested: (awardsCategories, action) => {
      awardsCategories.loading = true;
    },

    awardsCategoriesReceived: (awardsCategories, action) => {
      awardsCategories.awardsCats = action.payload;
      awardsCategories.loading = false;
    },

    awardsCategoriesRequestFailed: (awardsCategories, action) => {
      awardsCategories.loading = false;
    },

    getNationalRequested: (getNational, action) => {
      getNational.loading = true;
    },

    getNationalReceived: (getNational, action) => {
      getNational.national = action.payload;
      getNational.loading = false;
    },

    getNationalRequestFailed: (getNational, action) => {
      getNational.loading = false;
    },
  },
  
});

export default registrationSlice;
const {
  registrationRequestFailed,
  registrationReceived,
  registrationRequested,
  awardsCategoriesRequested,
  awardsCategoriesReceived,
  awardsCategoriesRequestFailed,
  getNationalRequested,
  getNationalReceived,
  getNationalRequestFailed,
} = registrationSlice.actions;


export function registration (data)  {
  const url = `Registration/Register`;
  
    apiCallBegan({
      url,
      method: "post",
      data: data,
      onStart: registrationRequested.type,
      onSuccess: registrationReceived.type,
      onError: registrationRequestFailed.type,
      newbaseURL:"https://api.tahbeer.ae/"
    })
  
};

export const getAwardsCategories = () => (dispatch) => {
  const lang = localStorage.getItem("language")
  const Awards = "award";
  const url = `Registration/GetLookup?key=${Awards}&lang=${lang}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "get",
      Credential:true,
      onStart: awardsCategoriesRequested.type,
      onSuccess: awardsCategoriesReceived.type,
      onError: awardsCategoriesRequestFailed.type,
      newbaseURL:"https://api.tahbeer.ae/"
    })
  );
};
export const getNational = () => (dispatch) => {
  const lang = localStorage.getItem("language");

  const Countries = "Countries";
  const url = `Registration/GetLookup?key=${Countries}&lang=${lang}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "get",
      Credential:true,
      onStart: getNationalRequested.type,
      onSuccess: getNationalReceived.type,
      onError: getNationalRequestFailed.type,
      newbaseURL:"https://api.tahbeer.ae/"
    })
  );
};

