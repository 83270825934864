import axios from "axios";
import * as actions from "./api";

const api =
  ({ dispatch }) =>
    (next) =>
      async (action) => {

        if (action.type !== actions.apiCallBegan.type) return next(action);

        const { url, method, data, onStart, onSuccess, onError, newbaseURL } = action.payload;

        if (onStart) dispatch({ type: onStart });

        next(action);

        try {

          const response = await axios.request({
            baseURL: newbaseURL ? newbaseURL : window.cfg ? window.cfg.serverUrl : "https://tahbeer.ae/THQAPI/api/",
            url,
            method,
            data,
            headers: {
              "X-Content-Type-Options": "nosniff",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
              "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
              "X-API-Key": xyz()
            }
          });
          function xyz() {
            const encryptedString = 'OEpkOG1lRWdORHJSaE1iRHBkQkY3ZTRENEtpYjQ2ZHdMOFpFNUVnaUwxaUw1WTNkelJNZnVqYjlnZUZkaj==';
            return atob(encryptedString);
          }

          dispatch(actions.apiCallSucess(response.data));
          // Specific
          if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
        } catch (error) {
          // General
          dispatch(actions.apiCallFailed(error.message));
          // Specific
          if (onError) dispatch({ type: onError, payload: error.message });
        }
      };

export default api;
