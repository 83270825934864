import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const getVisitorNumberSlice = createSlice({
  name: "general",
  initialState: {
    getVisitorNumber: 0,
    loading: false,
  },

  reducers: {
    getVisitorNumberRequested: (getVisitorNumber, action) => {
      getVisitorNumber.loading = true;
    },

    getVisitorNumberReceived: (getVisitorNumber, action) => {
      getVisitorNumber.getVisitorNumber = action.payload;
      getVisitorNumber.loading = false;
    },

    getVisitorNumberRequestFailed: (getVisitorNumber, action) => {
      getVisitorNumber.loading = false;
    },
  },
});

export default getVisitorNumberSlice;
const lang = localStorage.getItem("language")
  ? localStorage.getItem("language") == "en"
    ? 1
    : 2
  : 2;
const { getVisitorNumberRequestFailed, getVisitorNumberReceived, getVisitorNumberRequested } =
  getVisitorNumberSlice.actions;

const url = `Search/GetVisitorNumber?lang=${lang}`;

export const getVisitorNumber = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "GET",
      onStart: getVisitorNumberRequested.type,
      onSuccess: getVisitorNumberReceived.type,
      onError: getVisitorNumberRequestFailed.type,
    })
  );
};
