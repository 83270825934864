import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan, showMenu } from "./api";

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    list: [],
    loading: false,
    show: false,
  },

  reducers: {
    menuShow: (menu, action) => {
      menu.show = !menu.show;
    },
    menuRequested: (menu, action) => {
      menu.loading = true;
    },

    menuReceived: (menu, action) => {
      menu.list = action.payload;
      menu.loading = false;
    },

    menuRequestFailed: (menu, action) => {
      menu.loading = false;
    },
  },
});

export default menuSlice;
const lang = localStorage.getItem("language")
  ? localStorage.getItem("language") == "en"
    ? 1
    : 2
  : 2;
const { menuRequestFailed, menuReceived, menuRequested, menuShow } =
  menuSlice.actions;

const url = `SiteStructure/GetMenu?langId=${lang}`;

export const loadMenu = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "GET",
      onStart: menuRequested.type,
      onSuccess: menuReceived.type,
      onError: menuRequestFailed.type,
    })
  );
};

export const menuActions = menuSlice.actions;
