import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ar from "./ar.json";
import en from "./en.json";
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: { translation: en },
      ar: { translation: ar },
    },
    lng: "ar", // if you're using a language detector, do not define the lng option
    fallbackLng: "ar",
    debug: false,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

if (localStorage.getItem("language")) {
  i18n.changeLanguage(localStorage.getItem("language"));
} else {
  localStorage.setItem("language", "ar");
}

export default i18n;
