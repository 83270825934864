import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const genericConetent = createSlice({
  name: "genericConetent",
  initialState: {
    data: {},
    categoryData: [],
    contactUs:[],
    loading: false,
  },

  reducers: {
    genericConetentRequested: (menu, action) => {
      menu.loading = true;
    },

    genericConetentReceived: (menu, action) => {
      menu.data = action.payload;
      menu.loading = false;
    },

    genericConetentRequestFailed: (menu, action) => {
      menu.loading = false;
    },
    genericConetentCategoryRequested: (menu, action) => {
      menu.loading = true;
    },

    genericConetentCategoryReceived: (menu, action) => {
      menu.categoryData = action.payload;
      menu.loading = false;
    },

    genericConetentCategoryRequestFailed: (menu, action) => {
      menu.loading = false;
    },
    getcontactUsRequested: (menu, action) => {
      menu.loading = true;
    },

    getcontactUsReceived: (menu, action) => {
      menu.contactUs = action.payload;
      menu.loading = false;
    },

    getcontactUsRequestFailed: (menu, action) => {
      menu.loading = false;
    },
  },
});

export default genericConetent;
const lang = localStorage.getItem("language")
  ? localStorage.getItem("language") == "en"
    ? 1
    : 2
  : 2;
const {
  genericConetentRequestFailed,
  genericConetentReceived,
  genericConetentRequested,
} = genericConetent.actions;

export const getGenericContentByUniqueName = (uniquename) => (dispatch) => {
  const url = `GenericContent/ContentByUniqueName?uniquename=${uniquename}&langId=${lang}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "GET",
      onStart: genericConetentRequested.type,
      onSuccess: genericConetentReceived.type,
      onError: genericConetentRequestFailed.type,
    })
  );
};
const {
  genericConetentCategoryRequestFailed,
  genericConetentCategoryReceived,
  genericConetentCategoryRequested,
} = genericConetent.actions;
export const getGenericContentsByCategory = (uniquename) => (dispatch) =>{
  const url =`GenericContent/GenericContentsByCategory?categoryUniqueName=${uniquename}&langId=${lang}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "GET",
      onStart: genericConetentCategoryRequested.type,
      onSuccess: genericConetentCategoryReceived.type,
      onError: genericConetentCategoryRequestFailed.type,
    })
  );
}
const {
  getcontactUsRequestFailed,
  getcontactUsReceived,
  getcontactUsRequested,
} = genericConetent.actions;
export const getcontactUs = () => (dispatch) =>{
  const url =`GenericContent/GenericContentsByCategory?categoryUniqueName=contact.us&langId=${lang}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "GET",
      onStart: getcontactUsRequested.type,
      onSuccess: getcontactUsReceived.type,
      onError: getcontactUsRequestFailed.type,
    })
  );
}
